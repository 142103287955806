<template>
  <div class="discount-main-container">
    <div style="width: 400px" class="flex-column-start">
      <span class="font-regular convert-title">{{
        $trans(
          '请输入您的兑换码兑换线上优惠券',
          '请输入您的兑换码兑换线上优惠券'
        )
      }}</span>
      <div class="convert-input-wrap line-bottom flex">
        <input
          type="text"
          v-model="cdkey"
          :placeholder="$trans('请输入兑换码', '请输入兑换码')"
          class="font-regular"
        />
      </div>
      <div class="btn-fill" @click="couponHandle">
        {{ $trans('兑换', '兑换') }}
      </div>
    </div>
  </div>
</template>
<script>
import { couponHandle } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      cdkey: '',
    }
  },
  methods: {
    // 兑换优惠券
    couponHandle() {
      if (!this.cdkey) {
        this.$message.warning(this.$trans('请输入兑换码', '请输入兑换码'))
        return
      }
      let params = {
        code: this.cdkey,
        userId: this.$store.state.userId,
      }
      couponHandle(params).then((res) => {
        if (res.code == '200') {
          this.$message.success(this.$trans('兑换成功', '兑换成功'))
        } else {
          this.$message.error(this.$trans('兑换失败', '兑换失败'))
        }
        this.cdkey = ''
      })
    },
  },
}
</script>
<style scoped>
.discount-main-container {
  display: flex;
  justify-content: center;
}
.convert-title {
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.convert-input-wrap input {
  border: none;
  outline: none;
  line-height: 50px;
  width: 400px;
  /* padding: 0 20px; */
}
.btn-fill {
  margin-top: 100px;
}
</style>
